/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://2vqzwhquejg2vadw2ysltahn4i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5kupegc7pvc7ben4iayxc2gxhi",
    "aws_cognito_identity_pool_id": "us-west-2:4a5baacb-6d46-4d7f-b626-ac92f283d73d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_nVygKz6oD",
    "aws_user_pools_web_client_id": "1fkhak4bf05go9oa8j3lc4l8si",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "myproducts110247-develop",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
