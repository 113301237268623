<!--footer section -->
<footer>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Gates Company</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->