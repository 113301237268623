<!-- header -->
<app-header></app-header>
<section>
  <ng-container *ngIf="products$ | async as products; else loading">
    <section class="section-b-space">
      <div class="container">
          <div class="row gx-3">
              <div class="col">
                <div class="card shadow p-3 mb-5 bg-white rounded">
                  <div class="card-body">
                    <h4 class="card-title">SILVER</h4>
                    <p class="card-text">
                      <strong>Purchase Price:</strong> {{ totalSILVERPurchase | currency}} <br />
                      <strong>Total Troy Ounces:</strong> {{ totalSILVERWeight }} <br />
                      <strong>Number Of Coins:</strong> {{ totalSILVERPieces }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card shadow p-3 mb-5 bg-white rounded">
                  <div class="card-body">
                    <h4 class="card-title">COPPER</h4>
                    <p class="card-text">
                      <strong>Total Purchase Price:</strong> {{ totalCOPPERPurchase | currency }} <br />
                      <strong>Total Troy Ounces:</strong> {{ totalCOPPERWeight }} <br />
                      <strong>Number Of Coins:</strong> {{ totalCOPPERPieces }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card shadow p-3 mb-5 bg-white rounded">
                  <div class="card-body">
                    <h4 class="card-title">CLAD</h4>
                    <p class="card-text">
                      <strong>Total Purchase Price:</strong> {{ totalCLADPurchase | currency}} <br />
                      <strong>Total Troy Ounces:</strong> {{ totalCLADWeight }} <br />
                      <strong>Number Of Coins:</strong> {{ totalCLADPieces }}
                    </p>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </section>
  </ng-container>
</section>
<ng-template #loading>
  <div class="container">
    <div class="row justify-content-center">
      <div class="spinner-border" role="status" style="color: #ff4c3b;">
        <span class="visually-hidden" >Loading...</span>
      </div>
    </div>
  </div>
</ng-template>
<!-- footer -->
<app-footer-one></app-footer-one>
