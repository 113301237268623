<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/home" id="headerlogo">
                <img src="../../../assets/images/ShinyStacks.png" style="height: 6rem;">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div *ngIf="auth.isUserAuthenticated()">
              <a [routerLink]="['/logout']" style="color: #e4604a;">
                {{ auth.getUserName()}}
              </a>

            </div>
            <div *ngIf="!auth.isUserAuthenticated()">
              <a [routerLink]="['/login']" style="color: #e4604a;">
                <i class="fa-regular fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
