<app-header></app-header>

<!-- category -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <owl-carousel-o class="category-m no-arrow">
                    <ng-container *ngFor="let cats of categories">
                      <ng-template carouselSlide>
                        <div>
                          <div class="category-wrapper">
                            <div>
                              <img [src]="cats.image" class="img-fluid w-auto" alt="">
                              <h4>{{cats.title}}</h4>
                              <ul class="category-link" [innerHTML]="cats.text"></ul>
                              <a [routerLink]="cats.url" class="btn btn-outline">
                                 view
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- category end -->

<!-- footer -->
<app-footer-one></app-footer-one>