<!-- header -->
<app-header></app-header>
<amplify-authenticator [hideSignUp]="true">
    <ng-template
        amplifySlot="authenticated"
        let-user="user"
        let-signOut="signOut"
    >
        <button (click)="signOut()">Sign Out</button>
    </ng-template>
</amplify-authenticator>

<!-- footer -->
<app-footer-one></app-footer-one>